import React, {useEffect} from "react";

import loadable from '@loadable/component';
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import NoSsr from '@mui/material/NoSsr';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/source-sans-pro";

const Layout = loadable(() => import('../components/common/Layout'));
const Seo = loadable(() => import('../components/seo'));
const SliderText = loadable(() => import('../components/SliderText'));
const ServicesList = loadable(() => import('../components/ServicesList'));
const Slides = loadable(() => import('../components/Slides'));

const IndexPage = () => {
  useEffect(() =>{
    sal({
      threshold: 1,
      once: false,
    });
  });
  return (
    <NoSsr>
  <Layout>
      <Seo title="Inicio" />
      <SliderText data-sal="slide-up" style={{minHeight: "100vh"}} id="top"/>
      <ServicesList data-sal="slide-up"/>
      <Slides data-sal="slide-up" />
  </Layout>
  </NoSsr>
)}

export default IndexPage
